window.addEventListener('error', evt => {
  console.log(evt);
});

import $ from 'lib/jquery';
import Frowser from 'frowser';

(async () => {
  const ua = Frowser.default.parse(navigator.userAgent);

  console.groupCollapsed(`📱 SIDEKICK:DEVICE`);
  console.log('AGENT', navigator.userAgent);
  console.log('BROWSER', ua.browser);
  console.log('ENGINE', ua.engine);
  console.log('OS', ua.os);
  console.groupEnd();

  if ($.Pages.current) return;
  if (!ua.browser.name.includes('Safari')) await $.Pages.open({ path: '/errors/invalid-browser' });
  else if (!ua.os.name.includes('iPad')) await $.Pages.open({ path: '/errors/invalid-device' });
  else if (!$.Sidekick.configured) await $.Pages.open({ path: '/errors/not-configured' });
  else if (!$.Sidekick.standalone) await $.Pages.open({ path: '/errors/not-standalone' });
  else await $.Sidekick.init();
})();
